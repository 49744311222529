import env from "app/utils/env";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const getProfileApiUrl = () => {
	return `${env("USER_AUTH_API_URL")}/me`;
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};

export const getMenuEntries = (shop = "de-AT") => {
	const [, countryCode] = shop.split("-");
	const domainSuffix = countryCode.toLowerCase();
	const isShopDe = shop === "de-DE";

	return [
		{
			title: {
				label: "Aktuelle Deals",
				link: `https://www.urlaubsguru.${domainSuffix}/aktuelle-deals/`,
			},
			submenu: [],
		},
		{
			title: {
				label: "Suchen & Buchen",
				link: `https://buchen.urlaubsguru.${domainSuffix}/`,
			},
			submenu: [],
		},
		{
			title: {
				label: isShopDe ? "Frühbucher" : "Flash Deals",
				link: isShopDe
					? `https://www.urlaubsguru.${domainSuffix}/fruehbucher/`
					: `https://flashdeals.urlaubsguru.${domainSuffix}/${shop}/listing/`,
			},
			submenu: [],
		},
	];
};
