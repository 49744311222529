import { PureComponent } from "react";
import RelativeLink from "app/pages/.shared/RelativeLink";
import PropTypes from "prop-types";
import "./aside-menu.scss";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import Separator from "app/pages/.shared/Separator";
import { getMenuEntries } from "src/@brand/appConstants";

class AsideMenu extends PureComponent {
	render() {
		const { onItemClick, shop } = this.props;

		return (
			<section className="aside-menu">
				<div className="aside-menu__header">
					<div className="aside-menu__close" onClick={onItemClick}>
						<i className="icon icon--cross-dark" />
					</div>
					<RelativeLink
						to={{ pathname: "/listing" }}
						className="aside-menu__logo"
						onClick={onItemClick}
					>
						<LogoBrandContainer />
					</RelativeLink>
				</div>
				<Separator />

				<nav className="aside-menu__nav">
					<ul className="nav">
						{getMenuEntries(shop).map(menuItem => (
							<li key={menuItem.title.label.link} className="nav__item">
								<RelativeLink
									className="nav__item-link"
									to={{ pathname: menuItem.title.link }}
								>
									{menuItem.title.label}
								</RelativeLink>
								<Separator />
								<li>
									{menuItem.submenu.map(submenuItem => (
										<li key={submenuItem.link} className="nav__subitem">
											<RelativeLink
												className="nav__subitem-link"
												to={{ pathname: submenuItem.link }}
											>
												{submenuItem.title}
											</RelativeLink>
											<Separator />
										</li>
									))}
								</li>
							</li>
						))}
					</ul>
				</nav>
			</section>
		);
	}
}

AsideMenu.propTypes = {
	onItemClick: PropTypes.func,
	shop: PropTypes.string,
};

export default AsideMenu;
